import React from 'react'

import { CampfireEnjinePageLayout } from '@/components/campfire/campfireEnjinePageLayout'
import { tempNewsList } from '@/constants/news/campfireEnjine'
import { CampfireEnjineHead } from '@/components/campfire/campfireEnjineHead'
import { NewsList } from '@/components/common/news'

export default function CampfireEnjineNews(): JSX.Element {
  return (
    <>
      <CampfireEnjineHead
        title="お知らせ| 株式会社CAMPFIRE ENjiNE"
        url="group/campfire-enjine/news"
      />
      <CampfireEnjinePageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/CAMPFIRE ENJINE/NEWS"
        breadcrumbsPath="/group/campfire-enjine/news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </CampfireEnjinePageLayout>
    </>
  )
}
